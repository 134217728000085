<template>
  <v-form
    :lazy-validation="true"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
  >
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("common.create") }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          :label="$t('gateway.fields.macAddress')"
          name="deveui"
          prepend-icon="mdi-domain"
          type="text"
          v-model="gateway.macAddress"
          :rules="macRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          :label="$t('gateway.fields.name')"
          name="name"
          prepend-icon="mdi-domain"
          type="text"
          v-model="gateway.name"
          :rules="nameRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          :label="$t('gateway.fields.latitude')"
          name="lat"
          prepend-icon="mdi-domain"
          type="text"
          v-model="gateway.latitude"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          :label="$t('gateway.fields.longitude')"
          name="long"
          prepend-icon="mdi-domain"
          type="text"
          v-model="gateway.longitude"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          :label="$t('tag.fields.applicationId')"
          :items="companyApps"
          item-text="name"
          item-value="applicationId"
          :loading="appsLoad"
          v-model="applicationId"
          :rules="applicationIdRule"
          prepend-icon="apps"
          autocomplete="off"
        ></v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" type="submit" x-large>{{
          $t("common.create")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "GatewayCreate",

  data() {
    return {
      submitted: false,
      valid: false,
      applicationId: null,
      gateway: {
        macAddress: "",
        name: "",
        latitude: "",
        longitude: "",
      },
      nameRules: [
        (v) =>
          !!v ||
          this.$t("validation.required", [this.$t("gateway.fields.name")]),
        (v) =>
          (v && v.length <= 255) ||
          this.$t("validation.max", [this.$t("gateway.fields.name"), 255]),
      ],
      macRules: [
        (v) =>
          !!v ||
          this.$t("validation.required", [
            this.$t("gateway.fields.macAddress"),
          ]),
        (v) =>
          (v && v.length <= 255) ||
          this.$t("validation.max", [
            this.$t("gateway.fields.macAddress"),
            255,
          ]),
      ],

      applicationIdRule: [
        (v) =>
          !!v ||
          this.$t("validation.required", [this.$t("tag.fields.applicationId")]),
      ],
    };
  },
  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),

    ...mapState("companies", {
      companyApps: "companyApps",
      appsLoad: "status.loading",
    }),
  },

  methods: {
    ...mapActions("gateways", ["createGateway"]),
    ...mapActions("companies", ["getCompanyApplications"]),

    updateDecoder(val) {
      this.decoder = val;
    },

    async handleSubmit() {
      if (!this.$refs.form.validate() || this.applicationId == null) {
        this.valid = false;
        return;
      }

      this.submitted = true;
      this.gateway.latitude = this.gateway.latitude.replace(",", ".");
      this.gateway.longitude = this.gateway.longitude.replace(",", ".");
      this.gateway.macAddress = this.gateway.macAddress
        .replace(" ", "")
        .replace("-", "");

      await this.createGateway({
        applicationId: this.applicationId,
        gatewayPayload: this.gateway,
      });

      this.$router.push("/gateways");
    },
  },
  created() {
    if (!this.hasCompany) this.$router.push("/");
    this.getCompanyApplications();
  },
};
</script>